<template>
  <div>
    <div class="content bgFFF paddingT20">
      <el-form ref="form" :model="formInline" label-width="120px">
        <el-form-item :label="$t('searchModule.Belonging_operator')" prop="operationId">
          <el-select v-model.trim="formInline.operationId" filterable size="15" @change="getOperationInfo">
            <el-option
                :label="value.operationName"
                :value="value.operationId"
                :key="value.operationId"
                v-for="value in tenantList"
            >
            </el-option>
          </el-select>
           <el-button type="primary" @click="$router.go(-1)" style="margin-left:100px;">返回</el-button>
        </el-form-item>
        
        <el-form-item label="异常车辆类型">
          <div class="types">
            <div
                v-for="(item, index) in types"
                :key="index"
                class="item bgFFF"
                @mouseover="mouseOver(index)"
                @mouseout="mouseOut(index)"
            >
              <span>{{ index + 1 }}</span>
              <span>{{ item.exceptDictName }}</span>
              <div class="icon" >
<!--                <AuthorityComponent-->
<!--                    ComponentName="i"-->
<!--                    :permission="['button.edit']"-->
<!--                    class="el-icon-edit"-->
<!--                    style="margin-right:5px;color:#0f6eff;cursor: pointer;"-->
<!--                    @click="editTag(item)"-->
<!--                />-->
                <!--   :permission="['button.delete']" -->
                <AuthorityComponent
                    v-if="authority.button.delete"
                    ComponentName="i"
                    v-show="curTag == index"
                    class="el-icon-remove-outline"
                    style="color:#F56C6C;cursor: pointer;"
                    @click="deleteTag(item)"
                />
              </div>
            </div>
            <!--    :permission="['button.add']" -->
            <AuthorityComponent
                ComponentName="div"
                class="item bgFFF"
                @click="addTag"
                v-if="authority.button.add"
            >
              <span />
              <span style="cursor: pointer;">创建类型标签</span>
              <i class="el-icon-circle-plus-outline" style="color:#0f6eff"/>
            </AuthorityComponent>
            
          </div>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog :visible.sync="innerVisible" :title="title" width="30%" append-to-body>
      <el-input v-model="tagName" maxlength="10" placeholder="请输入新的类型名称"/>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveTag('add')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      authority: '',
      tenantList: [],
      formInline: {
        operationId: ''
      },
      types: [],
      curTag: null,
      title: '创建类型',
      innerVisible: false,
      tagName: '',
      tagId: '',
      operationType: 1
    }
  },
  created() {
    this.authority =  this.$setAuthority("exceptionParkConfig")
    console.log("异常上报权限为==>", this.$setAuthority("exceptionParkConfig"));
    this.getTenantList();
  },
  methods: {
    // 所属运营商下拉框
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then(res => {
        if (res.state == 0) {
          this.tenantList = res.value;
          if (res.value.length) {
            this.formInline.operationId = res.value[0].operationId
            this.getCategoryList()
          }
        } else {
          this.$alert(res.desc);
        }
      })
    },
    // 所属运营商选择
    getOperationInfo(val) {
      this.formInline.operationId = val;
      this.getCategoryList()
    },
    // 广告类型列表查询
    getCategoryList() {
      const opt = {
        url: '/acb/2.0/dictionary/list',
        method: 'get',
        data: {
          operationId: this.formInline.operationId
        },
        success: res => {
          this.types = res.value
        }
      }
      this.$request(opt)
    },
    mouseOver(index) {
      this.curTag = index
    },
    mouseOut(index) {
      this.curTag = null
    },
    // 修改广告类型
    editTag(row) {
      this.title = '修改类型'
      this.innerVisible = true
      this.tagName = row.exceptDictName
      this.tagId = row.exceptDictId
      this.operationType = 2
    },
    // 创建广告类型
    addTag() {
      this.title = '创建类型'
      this.innerVisible = true
      this.tagName = ''
      this.tagId = ''
      this.operationType = 1
    },
    // 保存广告类型
    saveTag(flag) {
      // console.log(flag);
      // console.log(this.types, this.tagName);
      let item = this.types.find(item => {
          return item.exceptDictName == this.tagName;
      });
      if ((flag == 'add') && item) {
        this.$message('类型不可重复');
        return false
      }
      if (this.operationType !== 3 && this.tagName === '') {
        return
      }
      // console.log("this.operationType-->", this.operationType);
      let urlN = '/acb/2.0/dictionary/save'
      if (this.operationType === 2) {
        urlN = '/acb/2.0/advertCategory/operationAdvertCategoryUpdate'
      } else if (this.operationType === 3) {
        urlN = '/acb/2.0/dictionary/del'
      }
      this.innerVisible = false
      const opt = {
        url: urlN,
        method: 'post',
        data: {
          operationId: this.formInline.operationId,
          exceptDictId: this.tagId ? this.tagId : '',
          exceptDictName: this.tagName ? this.tagName : '',
        },
        config: {
          headers: {
            'Content-Type': this.operationType === 3 ? 'application/x-www-form-urlencoded;charset=UTF-8' : 'application/json;charset=UTF-8'
          },
        },
        success: res => {
          this.loading = false
          this.getCategoryList()
        },
        fail: err => {
          this.loading = false
        }
      }
      this.$request(opt)

      // url: '/acb/2.0/advertCategory/operationAdvertCategory',operationType 1新增 2修改 3删除
      // const opt = {
      //   url: urlN,
      //   method: this.operationType === 3 ? 'delete' : 'post',
      //   config: {
      //     headers: {
      //       'Content-Type': 'application/json;charset=UTF-8'
      //     },
      //   },
      //   data: {
      //     operationId: this.formInline.operationId,
      //     exceptDictId: this.tagId ? this.tagId : '',
      //     exceptDictName: this.tagName ? this.tagName : '',
      //   },
      //   success: res => {
      //     this.getCategoryList()
      //   },
      //   fail: err => {}
      // }
      // this.$request(opt)
    },
    // 删除广告类型
    deleteTag(item) {
      this.$confirm('确定删除此类型?', this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.operationType = 3
          this.tagId = item.exceptDictId
          this.tagName = item.exceptDictName
          this.saveTag('remove')
        }).catch(err => {})
    }
  }
}
</script>

<style lang="stylus" scoped>
.types
  display flex
  flex-direction column
  padding-bottom 50px
  .item
    width 250px
    height 40px
    border solid 1px #ccc
    border-top none
    display flex
    align-items center
    justify-content space-between
    box-sizing border-box
    padding 0 10px
    &:nth-child(1)
      border-top 1px solid #ccc
    .icon
      width 40px
      height 20px
      display flex
      align-items center
</style>
